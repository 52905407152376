import React, { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Search } from '..'
import { Size } from '../../types'
import { Routes } from '../../types/enums'
import { clearCoverageState } from '../../utils'

type State = {
  search?: string
}

type FormSearchProps = {
  placeholder?: string
  defaultValue?: string
  size?: Size
}

const FormSearch = ({ defaultValue, placeholder, size = 'big' }: FormSearchProps) => {
  const [state, setState] = useState<State>({ search: '' })
  const history = useHistory()

  const onChangeSearch = (search: string) => {
    setState({ ...state, search })
  }

  const onSearch = (event?: FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault()
    if (!state.search || state.search.length < 2) return
    clearCoverageState()
    history.push(Routes.SEARCH_QUERY.replace(':query', encodeURIComponent(state.search)))
  }

  return (
    <form onSubmit={onSearch}>
      <Search
        onSearch={onSearch}
        onChange={onChangeSearch}
        sx={{ mt: size === 'big' ? 5 : 0, mb: 5 }}
        size={size}
        value={defaultValue}
        placeholder={placeholder}
      />
    </form>
  )
}

export default FormSearch
