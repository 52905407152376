import { useEffect, useState, useCallback, SetStateAction, Dispatch } from 'react'
import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useOktaAuth } from '@okta/okta-react'
import graphQLConfig from '../../config/graphql'
import { typePolicies } from '.'

const httpLink = createHttpLink({
  uri: graphQLConfig.url,
})

type TClient = ApolloClient<NormalizedCacheObject> | null
type TSetClient = Dispatch<SetStateAction<TClient | null>>

export default function useApolloClient(): {
  client: TClient
  setClient: TSetClient
} {
  const { authService } = useOktaAuth()
  const [client, setClient] = useState<TClient>(null)

  const getToken = useCallback(async () => {
    const authLink = setContext((_, { headers }) => {
      return authService.getAccessToken().then((token: string) => ({
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }))
    })

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies,
      }),
      credentials: 'include',
    })

    setClient(client)
  }, [authService])

  useEffect(() => {
    getToken()
  }, [getToken])

  return { client, setClient }
}
