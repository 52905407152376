import React from 'react'
import { Flex } from 'theme-ui'
import { Icon, ButtonText, CompanyLogo, ReasonTextField } from '..'
import { LogoState } from '../../pages/CompanyForm/CompanyForm'
import { FileState } from '../../types'
import { cropImageToSquare } from '../../utils'
import { Paragraph } from '../primitives'
import { ReasonTextFieldProps } from '../UpdateCompanyField/ReasonTextField'
import { UploadFileInput } from '../UploadFile'

type Props = {
  state: LogoState[]
  logo: string
  reasonRequired?: boolean
  hideReason?: boolean
  invalidImage: boolean
  setInvalidImage(state: boolean): void
  onChangeFile(image: LogoState): void
} & ReasonTextFieldProps

export default function ({
  state,
  onChangeFile,
  reason,
  setReason,
  reasonRequired,
  logo,
  hideReason = false,
  invalidImage,
  setInvalidImage,
}: Props) {
  const handleChangeImage = async (files: FileState[]) => {
    try {
      const image = await cropImageToSquare(files[0])
      onChangeFile(image)
    } catch (error) {
      setInvalidImage(error)
    }
  }

  return (
    <>
      <UploadFileInput
        files={state}
        onChangeFile={handleChangeImage}
        accept={['.jpg', '.png']}
        content={
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Icon icon="uploadAlt" color="primary" iconSize={14} />
            <ButtonText sx={{ border: 'none', ml: 1 }} label="Choose file" />
          </Flex>
        }
      />
      <Flex sx={{ justifyContent: 'center', p: 3 }}>
        <CompanyLogo src={(state[0]?.src as string) || logo} />
      </Flex>
      {invalidImage && (
        <Paragraph sx={{ flex: 1, color: 'red' }}>
          {'Logo must be a valid image file (JPG/PNG)'}
        </Paragraph>
      )}
      {!hideReason && (
        <ReasonTextField reason={reason} setReason={setReason} required={reasonRequired} />
      )}
    </>
  )
}
