import { gql } from '@apollo/client'

export default gql`
  mutation OverrideOrChangeRQCompanyData(
    $input: [OverridesCompanyDataInput]!
    $isAppendData: Boolean
  ) {
    changeRequestCompanyData(input: $input, isAppendData: $isAppendData) {
      data
    }
  }
`
