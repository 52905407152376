import moment from 'moment'
import React from 'react'
import { Box, Divider, Flex, Text } from 'theme-ui'
import { Tooltip } from '..'
import CalendarSVG from '../../theme/svg/CalendarSVG'
import SourceSVG from '../../theme/svg/SourceSVG'
import { Partnership } from '../../types'
import { Routes } from '../../types/enums'
import { Paragraph } from '../primitives'

type Props = {
  data: Partnership[]
}

export default function PartnershipsList({ data }: Props) {
  const mappedData = (data || []).reduce((res, item) => {
    const record = res.find(({ partnershipId }) => partnershipId === item.partnershipId)

    const detail = { partnerName: item.partnerName, companyId: item.companyId }

    if (record) record.partnerDetails.push(detail)
    else res.push({ ...item, partnerDetails: [detail] })
    return res
  }, [] as Partnership[])

  return (
    <>
      {mappedData?.map((item, index) => {
        return (
          <>
            <Item key={item.partnershipId} partnership={item} sx={{ marginY: 5 }} />
            {index + 1 < mappedData.length && <Divider sx={{ my: 4 }} />}
          </>
        )
      })}
    </>
  )
}

type ItemProps = {
  partnership: Partnership
}
const Item = ({ partnership }: ItemProps) => {
  const handleOpenUrl = (companyId?: number) => {
    if (companyId) {
      const url = Routes.COMPANY.replace(':id', `${companyId}`)
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <Box>
      <Box sx={{ flexDirection: 'column', flex: 10 }}>
        <Tooltip
          sx={{ ml: -3, maxWidth: 700 }}
          content={
            partnership.partnerDetails.map(({ partnerName }) => partnerName).join(', ') || ''
          }
          id={partnership.partnershipId}
        >
          <Text
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
            }}
          >
            {partnership.partnerDetails.map(({ partnerName, companyId }, index) => {
              return (
                <span>
                  {
                    <Text
                      sx={{
                        cursor: companyId ? 'pointer' : 'default',
                        color: companyId ? 'primary' : 'default',
                        display: 'inline',
                      }}
                      onClick={() => {
                        handleOpenUrl(companyId)
                      }}
                    >
                      {partnerName || ''}
                    </Text>
                  }
                  {index !== partnership.partnerDetails.length - 1 && (
                    <Text sx={{ display: 'inline' }}>{', '}</Text>
                  )}
                </span>
              )
            })}
          </Text>
        </Tooltip>
        <Flex sx={{ mt: 3 }}>
          <Flex sx={{ mr: 6 }}>
            {partnership.source && <SourceSVG style={{ marginRight: 5 }} />}
            <Text sx={{ fontSize: 12 }}>{partnership.source || ''}</Text>
          </Flex>
          <Flex sx={{ flex: 1 }}>
            {partnership.date && <CalendarSVG style={{ marginRight: 5 }} />}
            <Text sx={{ fontSize: 12 }}>{moment(partnership.date).format('YYYY-MM-DD') || ''}</Text>
          </Flex>
        </Flex>
        {partnership.title && (
          <Paragraph sx={{ mt: 3 }} bold>
            {partnership.title}
          </Paragraph>
        )}
        {partnership.summary && <Paragraph sx={{ mt: 3 }}>{partnership.summary}</Paragraph>}
      </Box>
    </Box>
  )
}
