export type HealthCheckResult = {
  date: string
  mapped_companies: string
  mapped_funding: string
  mapped_investors: string
  unmapped_companies: string
  unmapped_funding: string
  missing_description: string
  missing_foundedyear: string
  missing_hqlocation: string
  missing_operating_status: string
  unmapped_country: string
  unmapped_investors: string
  static_sources: string
  active_overrides: string
  invalid_funding_date: string
  single_source: string
  news_articles: string
  news_coverage: string
  out_companies_have_profile: string
  apix_companies: string
  having_many_hq_locations: string
}

export const healthCheckFields: HealthCheckResult = {
  date: 'Date',
  mapped_companies: 'Mapped companies',
  mapped_funding: 'Mapped funding (B)',
  mapped_investors: 'Mapped investors',
  unmapped_companies: 'Unmapped companies',
  unmapped_funding: 'Unmapped funding (B)',
  missing_description: 'Missing description',
  missing_foundedyear: 'Missing founded Year',
  missing_hqlocation: 'Missing hq location',
  missing_operating_status: 'Missing operating status',
  unmapped_country: 'Unmapped country',
  unmapped_investors: 'Unmapped investors',
  static_sources: 'Static sources',
  active_overrides: 'Active overrides',
  invalid_funding_date: 'Invalid funding date',
  single_source: 'Single source',
  news_articles: 'News articles',
  news_coverage: 'News coverage',
  out_companies_have_profile: 'Out companies have profile',
  apix_companies: 'APIX companies',
  having_many_hq_locations: 'Companies have many hq locations'
}
