import { gql } from '@apollo/client'

export default gql`
  query getSourcePriority {
    getSourcePriority {
      source
      priority
    }
  }
`
