import { gql } from '@apollo/client'

export default gql`
  query GetHealthCheck {
    getHealthCheck {
      date
      mapped_companies
      mapped_funding
      mapped_investors
      unmapped_companies
      unmapped_funding
      missing_description
      missing_foundedyear
      missing_hqlocation
      missing_operating_status
      unmapped_country
      unmapped_investors
      static_sources
      active_overrides
      invalid_funding_date
      single_source
      news_articles
      news_coverage
      out_companies_have_profile
      apix_companies
      having_many_hq_locations
    }
  }
`
