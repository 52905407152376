import { gql } from '@apollo/client'
import { Fragments } from '.'

export default gql`
  query($companyId: Int!, $page: Int, $pageSize: Int) {
    getCompanyPartnerships(companyId: $companyId, page: $page, pageSize: $pageSize) {
      data {
        ...Partnership
      }
      total
    }
  }
  ${Fragments.partnership}
`
