import { Grid, Text } from '@theme-ui/components'
import React, { useState } from 'react'
import { Button, Checkbox } from '../..'
import { EnumCompanySource, EnumReverseCompanySource } from '../../../types/enums'
import { Investor } from '../../InvestorForm'
import { Paragraph } from '../../primitives'

type InvestorItemProps = {
  disabled?: boolean
  showSource?: boolean
  investor: Investor
  checked?: boolean
  onRemove(): void
  onCheck?(): void
}

export const INVESTOR_GRIDS = ['1fr 1fr 0.2fr 0.2fr']

const InvestorItem = ({
  disabled,
  showSource,
  investor,
  checked,
  onRemove,
  onCheck,
}: InvestorItemProps) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const canRemove = investor.source !== EnumCompanySource.BCG

  return (
    <>
      <Grid
        onMouseEnter={() => !disabled && setIsHover(true)}
        onMouseLeave={() => !disabled && setIsHover(false)}
        onMouseOver={() => !disabled && setIsHover(true)}
        columns={INVESTOR_GRIDS}
        sx={{
          alignItems: 'center',
          py: 1,
          height: 32,
        }}
      >
        <Text sx={{ overflowWrap: 'break-word', fontSize: 14 }}>
          {investor.investor_name || ''}
        </Text>
        <Paragraph>
          {showSource
            ? investor.source
              ? EnumReverseCompanySource[investor.source as keyof typeof EnumReverseCompanySource]
              : ''
            : investor.investor_type || ''}
        </Paragraph>
        {onCheck && <Checkbox disabled={disabled} onPress={onCheck} checked={checked} />}
        {isHover && canRemove && (
          <Button onPress={onRemove} icon="remove" size="tiny" variant="black" />
        )}
      </Grid>
    </>
  )
}
export default InvestorItem
