import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Updating } from '../../components'
import { Heading, Paragraph, Section } from '../../components/primitives'
import { GET_HEALTH_CHECK } from './graphql'
import strings from '../../strings'
import { healthCheckFields, HealthCheckResult } from './helpers'
import { Box } from '@theme-ui/components'
import { Palette } from '../../theme'
import { ErrorModal } from '../../components/ErrorModal'
import { formatLargeNumber } from '../../utils'

const maxWidth = 222
const fullWidth = '95vw'

const convertNumber = ['mapped_funding', 'unmapped_funding']

const HealthCheck = () => {
  const { healthCheck: copy } = strings

  const [state, setState] = useState<HealthCheckResult[]>([])
  const [error, setError] = useState<string>('')

  const { data, loading, error: err, refetch } = useQuery(GET_HEALTH_CHECK, {
    notifyOnNetworkStatusChange: true,
    onCompleted() {
      setState(data.getHealthCheck)
    },
    onError() {
      setError(err?.message || '')
    },
  })

  const formatNumber = (value: string) => (Math.abs(Number(value)) / 1.0e9).toFixed(2)

  return (
    <>
      <Heading
        sx={
          !data?.getHealthCheck
            ? {}
            : {
                width: `${fullWidth}`,
                mx: `calc((-${fullWidth} + 1024px)/2)`,
                maxWidth: `${fullWidth}`,
              }
        }
        as="h2"
      >
        {copy.title}
      </Heading>
      <Section
        sx={{
          mt: 5,
          ...(!data?.getHealthCheck
            ? {}
            : {
                width: `${fullWidth}`,
                mx: `calc((-${fullWidth} + 1024px)/2)`,
                maxWidth: `${fullWidth}`,
              }),
        }}
      >
        {loading ? (
          <Updating sx={{ py: 7 }} loading />
        ) : (
          <Box sx={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: 12,
                      minWidth: 222,
                      maxWidth: maxWidth,
                      position: 'sticky',
                      left: 0,
                      background: 'white',
                      boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Paragraph bold>Date</Paragraph>
                  </th>
                  {Object.keys(healthCheckFields).map((key, index) =>
                    key === 'date' ? undefined : (
                      <th
                        key={index}
                        style={{
                          textAlign: 'left',
                          padding: 12,
                          minWidth: 175,
                          maxWidth: maxWidth,
                        }}
                      >
                        <Paragraph bold>
                          {healthCheckFields[key as keyof HealthCheckResult]}
                        </Paragraph>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {state.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? Palette.gray03 : 'white',
                    }}
                  >
                    <th
                      style={{
                        textAlign: 'left',
                        padding: 12,
                        minWidth: 222,
                        maxWidth: maxWidth,
                        position: 'sticky',
                        left: 0,
                        background: index % 2 === 0 ? Palette.gray03 : 'white',
                        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <Paragraph>{item.date}</Paragraph>
                    </th>
                    {Object.keys(healthCheckFields).map((key, i) => {
                      const value = item[key as keyof HealthCheckResult]
                      return key === 'date' ? undefined : (
                        <th
                          key={i}
                          style={{
                            textAlign: 'left',
                            padding: 12,
                            minWidth: 175,
                            maxWidth: maxWidth,
                          }}
                        >
                          <Paragraph>
                            {value
                              ? convertNumber.includes(key)
                                ? formatNumber(value)
                                : formatLargeNumber(value)
                              : ''}
                          </Paragraph>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        )}
      </Section>
      {!!error && (
        <ErrorModal
          title={'Error'}
          message={error}
          onOK={() => {
            setError('')
            refetch()
          }}
        />
      )}
    </>
  )
}

export default HealthCheck
