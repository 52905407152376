import * as Sentry from '@sentry/react'

const isLocal = process.env.NODE_ENV === 'development'

export function initSentry() {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    sampleRate: 0.05,
    environment: process.env.NODE_ENV,
  })
}

export function logError(error: any, errorInfo: any = null) {
  if (isLocal) {
    return
  }

  Sentry.withScope(scope => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}

export function onError(error: any) {
  if (isLocal) {
    console.log(error)
    return
  }

  let errorInfo = {
    url: undefined,
  }

  if (!(error instanceof Error) && error.message) {
    errorInfo = error
    error = new Error(error.message)
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)
}
