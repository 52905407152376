import { EnumExpandStatus, EnumExpandStatusId } from './../types/enums'
import { EnumCompanyTypeSector } from '../types/enums'

export default {
  approvedRequests: 'Approved Requests',
  rejectedRequests: 'Rejected Requests',
  common: {
    logo: 'Expand FCT',
    backButton: 'BACK',
    updating: 'Updating',
    loading: 'Loading',
    undoButton: 'UNDO',
    clearAllButton: 'CLEAR ALL ROWS',
    viewHistory: 'View history',
    viewPendingChangeRequest: 'Pending request',
    viewMapping: 'View mapping',
    investorType: 'Investor Type',
    accessTokenExpired: 'Session has been expired. Please go back and login again.',
    cancel: 'Cancel',
    ok: 'OK',
    submit: 'Submit',
    enterReason: 'Enter the source/reason',
    backToCompanyRecord: 'Back to Company record',
    update: 'Update',
    ingestWarning:
      'Ingestion is currently running - any companies added will not be immediately visible in the database',
    etlWarning: `ETLs are currently running, no updates can be made to the database until ##time UTC`,
    appendDataCreated: 'Append data created',
    invalidFile: 'Only $type formats with max size of $size',
  },
  dimensions: {
    cluster: 'CLUSTERS',
    sector: 'SECTORS',
    'value chain': 'VALUE CHAINS',
    risk: 'RISKS',
  },
  error: {
    default: 'Some error occorred, please try again later',
    etl: 'ETL is currently running. Please try again later!',
  },
  header: {
    mappingZone: 'Mapping Zone',
    download: 'Download Global Data',
    addCompanyManually: 'Add Company Manually',
    taxonomyManagement: 'Taxonomy Management',
    tagManagement: 'Tag Management',
    reportManagement: 'Report Management',
    companyManagement: 'Company Management',
    healthCheck: 'Health Check',
    changeRequestManagement: 'Change Request Management',
    investorManagement: 'Investor Management',
    findFintechs: 'Find Fintechs',
  },
  aggregatedSource: {
    title: 'AGGREGATED SOURCE',
    settings: 'Settings',
  },
  companyMapping: {
    headers: {
      sector: 'SECTOR',
      sub1: 'Sub-Category 01',
      sub2: 'Sub-Category 02',
      sub3: 'Sub-Category 03',
      sub4: 'Sub-Category 04',
      sub5: 'Sub-Category 05',
    },
    primary: 'PRIMARY',
    aux: 'AUXILIARY',
    dimension: {
      cluster: 'CLUSTER',
    },
    dimension1ByTypeTech: {
      fintech: 'SECTOR',
      regtech: 'RISK',
      insurtech: 'VALUE CHAIN',
    },
  },
  tagGroup: {
    title: 'Tags',
    tagGroup: 'Tag Group',
    tags: 'Tags',
  },
  pages: {
    peopleForm: {
      title: 'People',
      fields: {
        name: 'Name',
        gender: 'Gender',
        emailAddress: "Email address",
        imageUrl: 'Image url',
        facebook: 'Facebook',
        linkedin: 'Linkedin',
        twitter: 'Twitter',
        jobTitle: 'Job title',
        numExits: 'Num exits',
        description: 'Description',
        numFoundedOrganizations: 'Num founded org',
        sourceUpdated: 'Source updated',
        apiAppend: 'API Append',
        source: 'Source',
        titleNames: 'Title',
        titleTypeNames: 'Title type',
      },
    },
    fundraising: {
      title: 'Fundraising',
      fields: {
        pitchDeckBucketKey: 'Pitch Deck',
        isFundraising: 'Is fundraising',
        proceedsUtilization: 'Proceeds Utilization',
        investorRelationsContact: 'Investor relations contact',
      },
      keys: {
        fundraising_id: 'Fundraising ID',
        pitch_deck_bucket_key: 'Pitch Deck',
        fundraising: 'Is fundraising',
        proceeds_utilization: 'Proceeds Utilization',
        investor_relations_contact: 'Investor relations contact',
      },
    },
    company: {
      actionButtonEdit: 'EDIT',
      actionButtonAdd: 'ADD',
      actionButtonMarkDuplicated: 'MARK AS DUPLICATED',
      actionButtonUnMarkDuplicated: 'UNMARK DUPLICATED',
      editSources: {
        settingsButton: 'SETTINGS',
        buttons: {
          back: 'BACK',
          save: 'SAVE',
        },
        body:
          'Below you can choose to remove a source from this company and/ or change your default source',
      },
      modal: {
        successEditSource: {
          button: 'Finish',
          body: {
            title: 'Success!',
            body:
              'This new default entry will be added to the internal database. It may take a while before it can be viewed, as it is currently loading onto the database.',
          },
        },
        successAdd: {
          button: 'Add taxonomy',
          body: {
            title: 'Success!',
            body: 'This new company has been added to the internal database.',
          },
        },
        successRemoveSource: {
          buttons: {
            viewCompanyRecord: 'View company record',
            goBackToSearch: 'Go back to search',
          },
          body: {
            title: 'Success!',
            body: 'Source has been removed!',
          },
        },
      },
    },
    addCompany: {
      title: 'Add Companies Manually',
      body: 'Add a new entry by choosing from the options below:',
      options: {
        upload: {
          title: 'Upload from computer',
          button: 'Upload',
          body:
            'You can use Excel, CSV file, etc to upload multiple companies onto the database. If you don’t have the right template, you can <a href="https://bcg.com" target="_blank">download it here</a>',
        },
        form: {
          title: 'Fill in the form',
          button: 'Fill in the form',
          body:
            'Fill in the required data straight-up on the platform to reduce potential interpreting errors',
        },
      },
    },
    addCompanyForm: {
      titles: {
        overview: 'Overview',
        taxonomy: 'Company Taxonomy',
        business: 'Profile',
        people: 'People',
        financials: 'Fundings',
        fundraising: 'Fundraising',
        acquisitions: 'Acquisitions',
        ipo: 'IPOs',
        news: 'News',
        useCase: 'Use Case',
        currentClients: 'Current Clients',
        technology: 'Technology',
        technologyCertification: 'Technology Certifications',
        technologyProvider: 'Technology Providers',
        licenses: 'Financial Services Licenses',
      },
      tabMenu: {
        overview: 'Overview',
        taxonomy: 'Company Taxonomy',
        business: 'Profile',
        people: 'People',
        financials: 'Fundings',
        fundraising: 'Fundraising',
        acquisitions: 'Acquisitions',
        ipos: 'IPOs',
        news: 'News',
        useCase: 'Use Case',
        technology: 'Technology',
      },
      fieldTitles: {
        locations: 'LOCATIONS',
        attachments: 'ATTACHMENTS',
        newEntry: 'NEW MANUAL ENTRY',
      },
      modals: {
        overrides: {
          title: 'Edit history',
        },
        location: {
          title: 'Add New Location',
        },
        alias: {
          title: 'Add New Other Name',
        },
        documentations: {
          title: 'Add New Attachments',
        },
        leave: {
          title: 'Are you sure you would like to leave this page?',
          buttons: {
            yes: 'Yes',
            no: 'No',
          },
        },
        viewCompanyRecord: 'View company record',
        addAnotherCompany: 'Add another company',
        logo: {
          title: 'Company logo',
        },
      },
      fields: {
        companyName: 'Company Name',
        foundedYear: 'Founded Year',
        companyStatus: 'Company Status',
        companyOtherNames: 'Other names',
        companyClosedDate: 'Closed Date',
        companyWebsite: 'Website Link',
        companyLogo: 'Logo URL',
        companyDescription: 'Company Description (150 words max.)',
        uploadBody: 'Upload any relevant documentation (.pdf, .ppt, .xls)',
        region: 'Region',
        country: 'Country',
        city: 'City',
        companyId: 'Company ID',
        expandStatus: 'FCT status',
        companyTwitterUrl: 'Twitter URL',
        companyFacebookUrl: 'Facebook URL',
        companyLinkedInUrl: 'LinkedIn URL',
        ftesRange: 'FTEs range',
        ftesExact: 'FTEs exact',
      },
      buttons: {
        cancel: 'Cancel',
        submit: 'Submit',
        save: 'Save',
        upload: 'Upload Files',
        addParnership: 'Add Partnership +',
        addMemberAdvisors: 'Add Member/Advisor +',
        addDepartments: 'Add Department +',
        next: 'Next',
        previous: 'Previous',
        addFundingRound: 'Add Funding Round',
        addLeadInvestor: 'Add Lead Investor +',
        addInvestor: 'Add Investor +',
        addAcquisitionRound: 'Add Acquisition Round',
        addIpoRound: 'Add IPO Round',
        ok: 'OK',
        addOtherName: 'Add Other Name +',
        addOtherLocation: 'Add Other Location +',
        addHeadquartersLocation: 'Add Headquarters Location +',
        return: 'Return',
        back: 'Back',
        backToCompanyRecord: 'Back to Company record',
      },
      taxonomy: {
        title: 'Company Taxonomy',
        categories: 'Categories',
        sector: 'SECTOR',
        buttons: {
          cancel: 'Cancel',
          submit: 'Submit',
          ok: 'OK',
          save: 'Save',
        },
        tabs: {
          primary: 'PRIMARY',
          aux: 'AUXILIARY',
        },
        types: {
          [EnumCompanyTypeSector.FIN]: 'FinTech',
          [EnumCompanyTypeSector.INS]: 'InsurTech',
          [EnumCompanyTypeSector.REG]: 'RegTech',
        },
        maps: {
          buttons: {
            fintech: 'FinTech',
            insuretech: 'InsurTech',
            regTech: 'RegTech',
            out: 'Out',
          },
          selected: {
            one: 'map selected',
            more: 'maps selected',
          },
        },
        tagMapping: {
          tagMaps: 'Tag Maps',
          clearAllRows: 'CLEAR ALL ROWS',
        },
        radio: {
          title: 'FinTech Type',
          options: {
            enabler: 'Enabler',
            disruptor: 'Disruptor',
          },
        },
        companyType: {
          FIN: 'FinTech',
          REG: 'RegTech',
          INS: 'InsurTech',
        },
        modals: {
          confirm: {
            title: 'Are you sure you want to add this company without a taxonomy mapping?',
            buttons: {
              yes: 'Yes',
              no: 'No',
            },
          },
          leave: {
            title:
              'You have unsaved changes on this page. Are you sure you would like to leave this page?',
            buttons: {
              yes: 'Yes',
              no: 'No',
            },
          },
        },
        error: {
          requiredPrimaryMapping:
            'Cannot add auxiliary mappings unless a primary mapping has been added',
          requiredPrimInsValueChain:
            "Insurtech primary mappings can't be added without a value chain",
          primInsurtechNeedBothClusterAndValueChain:
            'Insurtech primary mappings need both cluster and value chain',
          primFinNeedBothSectorAndCluster: 'Fintech primary mappings need both sector and cluster',
          emptyDimension:
            'Cannot map company without choosing any mappings. It can be mapped as Out instead',
          map1DimensionToBothPriOrAux:
            'Cannot have both primary mapping & auxiliary mapping with the same cluster.',
          onlyPriFinOrPrimInsOrPrimReg:
            'Only primary Fintech or Insurtech or Regtech mappings is allowed.',
        },
      },
      financials: {
        fields: {
          roundType1: 'Round Type 1',
          roundType2: 'Round Type 2',
          investment: 'Investment USD',
          source_investment: 'Source Investment',
          investment_currency: 'Investment Currency',
          date: 'Date',
          source: 'Source',
          valuation: 'Valuation USD',
          comment: 'Comment',
          apiAppend: 'API Append',
        },
        modals: {
          unfollowed: 'All your pending investors will not be save',
          addFundingRound: 'Add funding round',
          cancel: 'Cancel',
          save: 'Save',
        },
      },
      business: {
        title: 'Profile',
        categories: 'Categories',
        sector: 'SECTOR',
        buttons: {
          cancel: 'Cancel',
          submit: 'Submit',
        },
        fields: {
          products_services: 'Products & Services',
          business_revenue_model: 'Business & Revenue Model',
          target_clients: 'Target Clients',
          partnerships: 'Partnership',
          company_name: 'company_name',
          company_description: 'company_description',
          vision: 'Vision',
          key_metrics: 'Key Metrics',
          differentiators: 'Differentiators',
          num_of_partnerships: 'num_of_partnerships',
        },
      },
      acquisitions: {
        fields: {
          acquisition_date: 'Acquisition date',
          price: 'Price USD',
          sourcePrice: 'Source Price',
          priceCurrency: 'Price Currency',
          source: 'Source',
          comment: 'Comment',
          status: 'Status',
          apiAppend: 'API Append',
        },
        modals: {
          unfollowed: 'All your pending lead investors / investors will not be save',
          cancel: 'Cancel',
          save: 'Save',
          addAcquisitionRound: 'Add acquisition round',
        },
      },
      acquirees: {
        fields: {
          companyId: 'Company ID',
          companyName: 'Company Name',
          contactEmail: 'Contact Email',
          status: 'Status',
          foundedYear: 'Founded Year',
          closedDate: 'Close Date',
          valuation: 'Valuation',
          companyStage: 'Company Stage',
          ftes: 'Ftes',
          phoneNumber: 'Phone Number',
          source: 'Source',
          apiAppend: 'Api Append',
          url: 'Url',
          facebookUrl: 'Facebook Url',
          linkedinUrl: 'Linkedin Url',
          twitterUrl: 'Twitter Url',
          description: 'Description',
        },
      },
      ipos: {
        fields: {
          amount: 'Amount USD',
          share_price: 'Share Price USD',
          shares_outstanding: 'Shares Outstanding',
          shares_sold: 'Shares Sold',
          stock_exchange: 'Stock Exchange',
          stock_symbol: 'Stock Symbol',
          valuation: 'Valuation USD',
          went_public_on: 'Went Public On',
          apiAppend: 'API Append',
          source_amount: 'Source Amount',
          amount_currency: 'Amount Currency',
        },
        modals: {
          cancel: 'Cancel',
          save: 'Save',
          addIpoRound: 'Add IPO round',
        },
      },
      attachments: {
        modals: {
          buttons: {
            ok: 'OK',
            cancel: 'Cancel',
            yes: 'Yes',
            no: 'No',
          },
          confirm: {
            delete: 'Are you sure to $action this attachment?',
            changeAttachmentType: `This company already had an active attachment of this type. Do you want to make this attachment the new active one and disable the other one?`,
          },
        },
        fields: {
          name: 'Name',
          description: 'Descriptions',
          type: 'Type',
        },
        acceptTypes: {
          pdf: ['.pdf', 'application/pdf'],
          ppt: [
            '.ppt',
            '.pptx',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          ],
        },
      },
      investor: {
        investorManagement: 'Investor Management',
        setAsLeadInvestor: 'Set as Lead Investor',
        labels: {
          investors: 'INVESTORS',
          investorName: 'Investor Name',
          investorType: 'Investor Type',
          setLeadInvestor: 'Set as Lead Investor',
        },
        modals: {
          overrides: {
            title: 'Edit history',
          },
          leave: {
            title: 'Are you sure you would like to leave this page?',
            buttons: {
              yes: 'Yes',
              no: 'No',
            },
          },
        },
        management: {
          titles: {
            management: 'Investor Management',
            create: 'Create new',
            merge: 'Merge investors',
            edit: 'Edit investor',
            unMerge: 'Edit merged investor',
          },
          message: {
            noData: 'NO DATA AVAILABLE',
            duplicate: 'This investor has existed',
            unMerge: 'Are you sure to unmerge $name?',
            delete: 'Are you sure to delete $name?',
          },
          buttons: {
            merge: 'Merge',
            mergeInvestors: 'Merge investors',
            create: 'Create',
            createNew: 'Create new',
            delete: 'Swipe to Delete',
            unMerge: 'Swipe to unmerge',
            unMergeAll: 'Unmerge all',
            saveMerge: 'Save change',
            keep: 'No, keep it',
            remove: 'Yes, remove it',
            cancel: 'Cancel',
            save: 'Save',
            ok: 'OK',
          },
          placeholder: {
            search: 'Search for investor name',
          },
        },
        confirm: {
          unfollow: 'Are you sure to $status this investor?',
          setLead: 'Are you sure to set $name to be $type investor?',
        },
        error: {
          addCRToFunding: 'You must approved this change request before add to funding.',
        },
      },
    },
    search: {
      title: 'Global Search',
      placeholder: 'Name or URL of new or registered company',
      categoryOptions: [
        { value: 'fintech', label: 'FinTech' },
        { value: 'regtech', label: 'RegTech' },
        { value: 'insurtech', label: 'InsurTech' },
        { value: 'all', label: 'All' },
      ],
      mappingOptions: [
        { value: 'all', label: 'All' },
        { value: 'mapped', label: 'Mapped' },
        { value: 'unmapped', label: 'Unmapped' },
      ],
    },
  },
  companyDetail: {
    duplicated: 'DUPLICATED',
    buttons: {
      overview: 'Overview',
      financials: 'Fundings',
      fundraising: 'Fundraising',
      business: 'Profile',
      people: 'People',
      acquisitions: 'Acquisitions',
      ipos: 'IPOs',
      news: 'News',
      acquirees: 'Acquirees',
      'use-case': 'Use Case',
      technology: 'Technology',
      partnerships: 'Partnerships',
    },
    overview: {
      companyId: 'COMPANY ID',
      url: 'URL',
      foundedYear: 'FOUNDED YEAR',
      companyType: 'COMPANY TYPE',
      status: 'COMPANY STATUS',
      closedDate: 'CLOSED DATE',
      logoUrl: 'LOGO URL',
      contactEmail: 'CONTACT EMAIL',
      phoneNumber: 'PHONE NUMBER',
      description: 'DESCRIPTION',
      location: 'LOCATION',
      otherNames: 'OTHER NAMES',
      lastFundingType: 'LAST FUNDING TYPE',
      attachments: 'ATTACHMENTS',
      categories: 'CATEGORIES',
      categoryGroups: 'CATEGORY GROUPS',
      industries: 'INDUSTRIES',
      tags: 'TAGS',
      numberEmployee: 'NUMBER OF EMPLOYEES',
      revenue: 'REVENUE',
      fintechType: 'FINTECH TYPE',
      facebookUrl: 'FACEBOOK URL',
      linkedinUrl: 'LINKEDIN URL',
      twitterUrl: 'TWITTER URL',
      ftesExact: 'FTES EXACT',
      ftesRange: 'FTES RANGE',
    },
    financials: {
      valuation: 'VALUATION',
      fundingTotal: 'FUNDING TOTAL',
      fundingStage: 'FUNDING STAGE',
      lastFundingDate: 'LAST FUNDING DATE',
      equityFundingTotal: 'EQUITY FUNDING TOTAL',
      lastFundingAt: 'LAST FUNDING AT',
      leadInvestor: 'LEAD INVESTOR',
      lastFundingAmount: 'LAST FUNDING AMOUNT',
      fundingRounds: {
        heading: 'FUNDING ROUNDS',
        date: 'DATES',
        quarter: 'QUARTER',
        comment: 'COMMENT',
        roundTypes: 'ROUND TYPES',
        source: 'SOURCE',
        valuation: 'VALUATION',
        valuation_usd: 'VALUATION USD',
        investment: 'INVESTMENT',
        investment_usd: 'INVESTMENT USD',
        investors: 'INVESTORS',
        leadInvestor: 'LEAD INVESTOR',
        otherInvestors: 'OTHER INVESTORS',
        totalFunding: 'TOTAL FUNDING',
        notAvailable: 'Not available',
      },
    },
    acquisitions: {
      acquisition_date: 'DATES',
      source: 'SOURCE',
      price: 'PRICE',
      investors: 'INVESTORS',
      comment: 'COMMENT',
      price_usd: 'PRICE USD',
    },
    ipos: {
      amount: 'AMOUNT',
      amount_usd: 'AMOUNT USD',
      share_price: 'SHARE PRICE',
      share_price_usd: 'SHARE PRICE USD',
      shares_outstanding: 'SHARES OUTSTANDING',
      shares_sold: 'SHARES SOLD',
      stock_exchange: 'STOCK EXCHANGE',
      stock_symbol: 'STOCK SYMBOL',
      valuation: 'VALUATION',
      valuation_usd: 'VALUATION USD',
      went_public_on: 'WENT PUBLIC ON',
      source: 'SOURCE',
    },
    business: {
      productsServices: 'PRODUCTS & SERVICES:',
      businessRevenue: 'BUSINESS & REVENUE MODEL:',
      targetClients: 'TARGET CLIENTS:',
      partnerships: 'PARTNERSHIPS:',
      marketingStrategy: 'MARKETING STRATEGY:',
      vision: 'VISION:',
      keyMetrics: 'KEY METRICS:',
      differentiators: 'DIFFERENTIATORS:',
    },
    people: {
      team: 'TEAM',
      numberOfPeople: 'NUMBER OF EMPLOYEES:',
      boardMembers: 'BOARD MEMBERS & ADVISORS:',
      contactDetails: 'COMPANY CONTACT DETAILS:',
    },
  },
  searchResults: {
    tooManyResults:
      'More than {number} records have come up,  please narrow your search criteria to help refine your search ',
    companyNotInDatabase: 'This company is not in our database yet.',
    companyNotInBothDatabases: 'This company can’t be found in our database or our current APIs',
    heading_internal: 'INTERNAL DATABASE',
    heading_external: 'EXTERNAL DATABASE',
    buttonAddNewEntry: 'Add New Entry',
    body_internal: 'You have one or more existing entries in your database',
    body_external:
      'You can choose to either add external results from each API to an existing company on the database or you can create a new company entry ',
    subtitle: 'You have chosen to aggregate, please confirm your chosen default source',
    override: 'Override Visibility',
    ctas: {
      createNewCompany: 'Create new Company',
      aggregateSources: 'Add Sources To Existing Company',
    },
    aggregate: {
      confirmDefault: 'Confirm Default',
      goBack: 'Back',
    },
    modal: {
      successAdd: {
        button: 'Add taxonomy',
        body: {
          title: 'Success!',
          body: 'This new company has been added to the internal database.',
        },
      },
      confirmAggregate: {
        buttonBack: 'No, Go Back',
        buttonConfirm: 'Yes, Set As Default',
        body: {
          title: 'Are you sure you would like to continue with the Manual entry as your default?',
          body: '',
        },
      },
      errorAggregate: {
        buttonBack: 'Close',
        body: {
          title: 'Company Error',
          body: 'This source cannot be added. You have already added it to the internal database',
        },
      },
      errorByChangeRequest: {
        buttonBack: 'Close',
        body: {
          title: 'Error!',
          body: 'Some companies have change/append requests. Please resolve them first!',
        },
      },
      successAggregateSource: {
        buttons: {
          viewCompanyRecord: 'View company record',
          goBackToSearch: 'Go back to search',
        },
        body: {
          title: 'Success!',
          body: 'Company has been added to the database!',
        },
      },
    },
  },
  downloadCompanyData: {
    heading: 'Download Company Data',
    downloadData:
      'Download the data for all companies in our database or refine your search using the filters below.',
    filterBy: 'FILTER BY:',
    dropdowns: {
      category: 'Category',
      mapping: 'Mapping',
    },
    downloadButton: 'Download CSV File',
  },
  companyList: {
    defaultSource: 'Default',
  },
  overridesHistory: {
    noData: 'Empty',
    columns: {
      user: 'User',
      date: 'Date',
      value: 'Value',
      reason: 'Reason',
      selfDeclared: 'Self Declared',
      inputSource: 'Input Source',
    },
  },
  tagsConstantTexts: {
    TAGS: 'TAGS',
    INTERNAL_TAGS: 'Internal Tags',
    EXTERNAL_TAGS: 'External Tags',
    WEBSITE_KEYWORDS: 'Website Keywords',
  },
  merge: {
    tab: {
      primary: 'Primary Taxonomy',
      auxiliary: 'Auxiliary Taxonomy',
      tag: 'Tags',
      profile: 'Company Profile',
    },
    label: {
      primary: 'Choose Primary Taxonomy',
      auxiliary: 'Select Auxiliary Taxonomy',
      fintechType: 'Select Fintech Type',
      tag: 'Select Tags',
      profile: 'Fill Company Profile',
      useCase: 'Fill Company Use Case',
      fundraising: 'Select Company Fundraising',
      technology: 'Fill Company Technology',
      overrides: 'Resolve Companies Override Conflicts And Visibility',
      location: 'Choose Location You Would Like To Keep (All others will be remove)',
      summary: {
        pri: 'Primary Taxonomy',
        aux: 'Auxiliary Taxonomy',
        fin: 'Fintech Type',
        tag: 'Tags',
        profile: 'Company Profile',
        useCase: 'Company Use Case',
        fundraising: 'Company Fundraising',
        technology: 'Company Technology',
        overrides: 'Resolve Overrides Conflicts',
        locations: 'Headquater Locations',
      },
    },
    taxonomyTab: [
      { value: 'fintech', label: 'FinTech' },
      { value: 'insurtech', label: 'InsurTech' },
      { value: 'regtech', label: 'RegTech' },
      { value: 'out', label: 'Out' },
    ],
    dimensionType: [
      { value: 'cluster', label: 'Clusters' },
      { value: 'value chain', label: 'Value Chain' },
    ],
  },
  tagManagement: {
    message: {
      delete: 'Are you sure to delete this $name?',
      duplicateTag:
        'A tag already exists in the destination folder with the same name, please choose one of the following options',
      nameExists: 'This name already exists',
    },
    buttons: {
      save: 'Save',
      delete: 'Delete',
      reset: 'Reset',
      confirm: 'Confirm',
      cancel: 'Cancel',
      addGroup: 'Create Group',
      addTag: 'Create Tag',
      move: 'Move selected tags',
    },
  },
  mappingZone: {
    grid: {
      company: 'Company',
      website: 'Website',
      amount: 'Funding amount',
      source: 'Source',
    },
    filter: {
      geography: 'Geography',
      amount: 'Funding amount',
      year: 'Launch Year',
      type: 'Investor Type',
      investor: 'Investor',
      region: 'Region',
      sub1: 'Sub Region 1',
      sub2: 'Sub Region 2',
      country: 'Country',
    },
    buttons: {
      save: 'Save',
      delete: 'Delete',
      reset: 'Reset',
      confirm: 'Confirm',
      cancel: 'Cancel',
      filter: 'Filter',
      addGroup: 'Create Group',
      addTag: 'Create Tag',
      move: 'Move selected tags',
      map: 'Map',
      merge: 'Map & Merge',
    },
  },
  taxonomyManagement: {
    title: 'Taxonomy Management',
    label: { fintech: 'Fintech', insurtech: 'Insurtech', regtech: 'Regtech' },
    error: {
      dimensionAlreadyExists: 'This name already exists',
    },
  },
  reportManagement: {
    title: 'Report Management',
  },
  companyManagement: {
    title: 'Company Management',
    buttons: {
      filter: 'filter',
    },
    placeholder: {
      search: 'Name or URL of new or registered company',
    },
  },
  bulkEdit: {
    label: {
      [EnumCompanyTypeSector.FIN]: 'Fintech',
      [EnumCompanyTypeSector.INS]: 'Insurtech',
      [EnumCompanyTypeSector.REG]: 'Regtech',
      [EnumCompanyTypeSector.OUT]: 'Out',
    },
  },
  healthCheck: {
    title: 'Health Check',
  },
  changeRequest: {
    title: 'Change Request Management',
    grid: {
      date: 'Date',
      user: 'User',
      name: 'Company Name',
      table: 'Table Name',
      field: 'Field Change',
      curValue: 'Current Value',
      newValue: 'New Value',
      reason: 'Reason',
      inputSource: 'Input Source',
      selfDeclared: 'Self Declared',
    },
  },
  suggestedMapping: {
    grid: {
      name: 'Company Name',
      current: 'Current Mapping',
      suggested: 'Suggested Mapping',
      isPrimary: 'Is Primary',
      input_user: 'Input User',
      created_date: 'Created Date',
      reviewed: 'Reviewed',
      reviewer: 'Reviewer ',
      reviewed_date: 'Reviewed Date',
    },
  },
  fctStatusId: {
    [EnumExpandStatusId.FOLLOWING]: EnumExpandStatus.FOLLOWING,
    [EnumExpandStatusId.UNFOLLOWED]: EnumExpandStatus.UNFOLLOWED,
    [EnumExpandStatusId.DUPLICATED]: EnumExpandStatus.DUPLICATED,
    [EnumExpandStatusId.TO_BE_EVALUATED]: EnumExpandStatus.TO_BE_EVALUATED,
  },
}
