import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Box, Flex, Grid, Text } from '@theme-ui/components'
import React, { useState } from 'react'
import { Button, Dropdown, FormConfirm } from '../..'
import { ETLRunTimeContext } from '../../../context'
import {
  editInvestor,
  getPendingCRgql,
  GET_COMPANY_OVERRIDES_HISTORY,
  searchInvestorByName,
} from '../../../pages/CompanyForm/graphql'
import {
  ColumnNames,
  OverridesInvestorInput,
  TableNames,
  trimTheString,
} from '../../../pages/CompanyForm/helpers'
import { investor } from '../../../pages/CompanyForm/mock'
import { onError } from '../../../sentry'
import strings from '../../../strings'
import { EnumCompanySource, EnumReverseCompanySource } from '../../../types/enums'
import { InvestorItemType, Investor } from '../../InvestorForm'
import { Paragraph } from '../../primitives'
import EditForm from '../EditForm'
import HeadingManagement from '../HeadingManagement'
import { EnumInvestorManagementScreen, ScreenType } from '../helpers'
import InvestorItem, { INVESTOR_GRIDS } from '../InvestorItem'

type EditInvestorProps = {
  state: Investor
  editState: Investor
  changeScreen(state: ScreenType): void
  onChange(state: Investor): void
  onSuccess(): void
  setError(error: string): void
  refetch(text: string): void
  hasPermission: boolean
  isOverridesUser: boolean
}

const EditInvestor = ({
  state,
  editState,
  changeScreen,
  onChange,
  onSuccess,
  setError,
  refetch,
  hasPermission,
  isOverridesUser,
}: EditInvestorProps) => {
  const {
    pages: {
      addCompanyForm: {
        investor: { management: copy },
      },
    },
  } = strings

  //Context
  const { checkTimeETL } = React.useContext(ETLRunTimeContext)

  const [dataInvestor, setDataInvestor] = useState<Investor[]>([])
  const [unMergeState, setUnMergeState] = useState<InvestorItemType[]>([])
  const [confirmState, setConfirmState] = useState<InvestorItemType[]>([])
  const [isInUnMerge, setInUnMerge] = useState<boolean>(Boolean)
  const [pendingUpdateInvestor, setPendingUpdateInvestor] = useState<OverridesInvestorInput[]>([])

  // GRAPHQL
  const [getInvestor, { data: queryData, loading: queryLoading }] = useLazyQuery(
    searchInvestorByName,
    {
      onCompleted() {
        setDataInvestor(queryData.searchInvestorByName.data)
      },
      fetchPolicy: 'network-only',
    }
  )

  const { investor_id, source } = editState || {}

  const { data: investorNameOverrides, loading: nameOverrideLoading } = useQuery(
    GET_COMPANY_OVERRIDES_HISTORY,
    {
      variables: {
        input: {
          tableName: TableNames.INVESTOR,
          columnName: ColumnNames.INVESTOR_NAME,
          companyId: -1,
          rowId: investor_id,
          source,
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: investorTypeOverrides, loading: typeOverrideLoading } = useQuery(
    GET_COMPANY_OVERRIDES_HISTORY,
    {
      variables: {
        input: {
          tableName: TableNames.INVESTOR,
          columnName: ColumnNames.INVESTOR_TYPE,
          companyId: -1,
          rowId: investor_id,
          source,
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: investorNameCR, loading: nameCRLoading } = useQuery(getPendingCRgql, {
    variables: {
      input: {
        tableName: TableNames.INVESTOR,
        columnName: ColumnNames.INVESTOR_NAME,
        companyId: -1,
        rowId: investor_id,
        source,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { data: investorTypeCR, loading: typeCRLoading } = useQuery(getPendingCRgql, {
    variables: {
      input: {
        tableName: TableNames.INVESTOR,
        columnName: ColumnNames.INVESTOR_TYPE,
        companyId: -1,
        rowId: investor_id,
        source,
      },
    },
    fetchPolicy: 'network-only',
  })

  const [updateInvestor, { loading: updateLoading }] = useMutation(editInvestor)

  const onSubmit = async () => {
    if (!checkTimeETL()) return
    try {
      const override = pendingUpdateInvestor.reduce((acc, cur) => {
        if (
          trimTheString(cur.oldValue) === cur.oldValue
            ? trimTheString(cur.oldValue) !== trimTheString(cur.newValue)
            : cur.oldValue !== cur.newValue
        ) {
          acc.push({ ...cur, newValue: trimTheString(cur.newValue) })
        }
        return acc
      }, [] as OverridesInvestorInput[])

      if (!!override.length || !!unMergeState.length) {
        await updateInvestor({
          variables: {
            edit_record: {
              override,
              unmerge: unMergeState.map(({ external_investor_id, investor_type }) => ({
                external_investor_id,
                investor_type,
              })),
            },
          },
        })
      }
      onSuccess()
    } catch (error) {
      setError(error.message)
      onError(error)
    }
  }

  const isDuplicated = dataInvestor.some(
    (item: Investor) =>
      item.investor_name === state.investor_name && item.investor_id !== state.investor_id
  )

  const hasChildren = !!state.children?.length
  const isUnMerge = hasChildren && state.children?.length !== unMergeState.length
  const childrenUnMergeAll =
    state.children?.filter(item => item.source !== EnumCompanySource.BCG) || []
  return !isInUnMerge ? (
    <>
      <HeadingManagement
        heading={hasChildren ? copy.titles.unMerge : copy.titles.edit}
        onPress={() => {
          !updateLoading && changeScreen(EnumInvestorManagementScreen.management)
        }}
        disabled={updateLoading}
      />

      <EditForm
        isOverridesUser={isOverridesUser}
        disabled={updateLoading}
        state={state}
        editState={editState}
        dataInvestor={dataInvestor}
        pendingUpdateInvestor={pendingUpdateInvestor}
        onChange={onChange}
        getInvestor={name => getInvestor({ variables: { name } })}
        setPendingUpdateInvestor={setPendingUpdateInvestor}
        overrides={{
          name: investorNameOverrides?.getCompanyOverrideHistory || [],
          type: investorTypeOverrides?.getCompanyOverrideHistory || [],
        }}
        pendingCR={{
          name: investorNameCR?.getCompanyPendingChangeRequest || [],
          type: investorTypeCR?.getCompanyPendingChangeRequest || [],
        }}
        refetch={refetch}
        loading={{
          name: nameOverrideLoading || nameCRLoading,
          type: typeOverrideLoading || typeCRLoading,
        }}
      />

      {isUnMerge && hasPermission && (
        <Box sx={{ px: 2, pt: 4 }}>
          <Grid
            columns={INVESTOR_GRIDS}
            sx={{
              alignItems: 'center',
            }}
          >
            <Paragraph bold>External investor name</Paragraph>
            <Paragraph bold>Source</Paragraph>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {state.children
              ?.filter(
                investor =>
                  !unMergeState.some(
                    item => item.external_investor_id === investor.external_investor_id
                  )
              )
              .map((investor, index) => (
                <InvestorItem
                  disabled={updateLoading}
                  showSource
                  key={index}
                  sx={{ px: 3, py: 4 }}
                  investor={investor}
                  onRemove={() => {
                    setConfirmState([investor])
                    setInUnMerge(true)
                  }}
                />
              ))}
          </Box>
        </Box>
      )}
      <Flex sx={{ mt: 5, justifyContent: 'space-between' }}>
        <Box>
          {isUnMerge && hasPermission && (
            <Button
              disabled={
                updateLoading ||
                nameOverrideLoading ||
                typeOverrideLoading ||
                !childrenUnMergeAll.length
              }
              sx={{ px: 2, color: 'orange' }}
              onPress={() => {
                if (!childrenUnMergeAll.length) {
                  return
                }
                setConfirmState(childrenUnMergeAll)
                setInUnMerge(true)
              }}
              label={copy.buttons.unMergeAll}
              icon="unMerge"
              variant="invert"
              color="gold"
              iconLeft
            />
          )}
        </Box>
        <Button
          label={hasChildren ? copy.buttons.saveMerge : copy.buttons.save}
          onPress={onSubmit}
          disabled={
            (!pendingUpdateInvestor.length && !unMergeState.length) ||
            !state.investor_name?.length ||
            !state.investor_type ||
            queryLoading ||
            isDuplicated ||
            updateLoading
          }
        />
      </Flex>
    </>
  ) : (
    <FormConfirm
      textConfirm={copy.buttons.unMerge}
      color="gold"
      bgColor="bgGold"
      onConfirm={() => {
        if (confirmState.length === childrenUnMergeAll?.length) {
          setUnMergeState(confirmState)
        } else setUnMergeState([...unMergeState, confirmState[0]])
        setInUnMerge(false)
      }}
      onCancel={() => setInUnMerge(false)}
      disabled={confirmState.some(({ investor_type }) => !investor_type?.length)}
    >
      <>
        <Text sx={{ textAlign: 'center', fontSize: 14, lineHeight: 1.5, mb: 4 }}>
          {copy.message.unMerge.replace('$name?', '')}
          <span style={{ fontWeight: 'bold' }}>{` ${confirmState
            .map(({ investor_name }) => investor_name)
            .join(', ')}?`}</span>
        </Text>
        {confirmState.map((item, index) => (
          <Grid
            key={index}
            sx={{ mt: 2, px: 3, alignItems: 'center' }}
            columns={['1fr 0.5fr 1.5fr']}
          >
            <Text sx={{ fontWeight: 'bold', fontSize: 14 }}>{item.investor_name || ''}</Text>
            <Text sx={{ fontWeight: 'bold', fontSize: 14 }}>
              {item.source
                ? EnumReverseCompanySource[item.source as keyof typeof EnumReverseCompanySource]
                : ''}
            </Text>
            <Dropdown
              name="type"
              placeholder="Type of investor"
              onChange={e => {
                let cloneState = [...confirmState]
                cloneState[index] = { ...cloneState[index], investor_type: e.target.value }
                setConfirmState(cloneState)
              }}
              options={investor}
            />
          </Grid>
        ))}
      </>
    </FormConfirm>
  )
}

export default EditInvestor
