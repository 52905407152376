import { useQuery } from '@apollo/client'
import React from 'react'
import { Box } from 'theme-ui'
import { GET_COMPANY_NEWS, GET_EXTERNAL_COMPANY_NEWS } from '../../../../pages/CompanyForm/graphql'
import { NewsModel } from '../../../../pages/CompanyForm/NewsForm'
import { IPagination, TagNewsData } from '../../../../types'
import { NewsList } from '../../../NewsList'
import Pagination from '../../../Pagination'
import Updating from '../../../Updating'

type Props = {
  data: TagNewsData
}

export default function CompanyDetailNews(props: Props) {
  const [pagination, setPagination] = React.useState<IPagination>({
    page: 1,
    pageSize: 10,
  })

  const { data, loading } = useQuery(GET_COMPANY_NEWS, {
    skip: !+props.data?.companyId || !props.data.isInternalCompany,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      companyId: +props.data.companyId,
      take: pagination.pageSize,
      skip: (pagination.page - 1) * pagination.pageSize,
      activeOnly: true,
    },
  })

  const { data: externalData, loading: externalLoading, refetch, networkStatus } = useQuery(
    GET_EXTERNAL_COMPANY_NEWS,
    {
      skip: !props.data?.companyId || props.data.isInternalCompany,
      variables: {
        companyId: props.data.companyId,
        source: props.data.source,
        limit: pagination.pageSize,
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const gotoPage = (p: IPagination) => {
    const newPagination = { ...p, page: p.page < 1 ? 1 : p.page }
    setPagination(newPagination)

    if (!props.data.isInternalCompany && newsListData?.length) {
      const isNext = p.page > pagination.page
      refetch({
        companyId: props.data.companyId,
        source: props.data.source,
        limit: p.pageSize,
        afterId: isNext ? newsListData[newsListData.length - 1]?.id : null,
        beforeId: !isNext ? newsListData[0]?.id : null,
      } as any)
    }
  }

  const queryResult = props.data.isInternalCompany
    ? data?.getCompanyNews
    : externalData?.getExternalCompanyNews

  const newsListData: NewsModel[] = queryResult?.result || []
  const total: number = queryResult?.total || 0

  if (loading || externalLoading || networkStatus === 4) return <Updating loading />
  return (
    <Box mt={6}>
      <NewsList data={newsListData} />
      <Pagination
        sx={{ justifyContent: 'center' }}
        maxVisibleButtons={3}
        hidePageButtons={!props.data.isInternalCompany}
        currentPage={pagination.page}
        pageSize={pagination.pageSize}
        totalPages={Math.ceil(total / pagination.pageSize)}
        changePage={page => {
          gotoPage({ ...pagination, page })
        }}
        changePageSize={pageSize => {
          gotoPage({ page: 1, pageSize })
        }}
      />
    </Box>
  )
}
