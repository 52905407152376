import React, { PropsWithChildren } from 'react'
import { FooterCTAs } from '..'
import { ButtonProps, ViewInterface } from '../../types'
import { Heading, Section } from '../primitives'

type Props = ViewInterface<
  PropsWithChildren<{
    title: string
    footerButtons?: ButtonProps[]
  }>
>

export default (props: Props) => {
  return (
    <>
      <Heading as="h2">{props.title}</Heading>
      <Section sx={{ mt: 6 }}>{props.children}</Section>
      {!!props.footerButtons?.length && <FooterCTAs buttons={props.footerButtons} />}
    </>
  )
}
