import React, { useState, useEffect } from 'react'
import { Flex, Label } from '@theme-ui/components'
import { IPagination } from '../../types'
import { Button, Drawer, Dropdown, FilterTemplate, Icon, TextField, Updating } from '..'
import { SortDirection } from '../../types/enums'
import strings from '../../strings'
import {
  FindFintechsFilterType,
  SortBy,
  IsRangeType,
  defaultSortBy,
  ESortFields,
} from '../../pages/FindFintechs/helpers'
import { FundingAmount, Geography, LaunchYear, FundingDate } from '../MappingZone/FilterForm'
import { useQuery } from '@apollo/client'
import { getGeography } from '../../pages/MappingZone/graphql'
import { FundingAmountType, GeographyType } from '../MappingZone/FilterForm/helpers'
import { sourceOptions } from '../../pages/CompanyManagement/CompanyFilter/helpers'
import { ftesRange, status } from '../../pages/CompanyForm/mock'

type FindFintechsProps = {
  filter: FindFintechsFilterType
  gotoPage(pagination: IPagination, newSortBy: SortBy): void
  onChangeFilter(filter: FindFintechsFilterType): void
  resetFilter(): void
  refetchAPI(): void
  isRange: IsRangeType
  setIsRange(props: IsRangeType): void
}

const CompanyFilter = ({
  gotoPage,
  filter,
  onChangeFilter,
  resetFilter,
  refetchAPI,
  isRange,
  setIsRange,
}: FindFintechsProps) => {
  const { companyManagement: copy } = strings
  const [currentFilter, setCurrentFilter] = useState<FindFintechsFilterType>(filter)
  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const [key, setKey] = useState<number>(0)
  const [errorForm, setErrorForm] = useState<string[]>([])
  // GRAPHQL
  const { data: geography, loading } = useQuery(getGeography)

  useEffect(() => {
    setCurrentFilter(filter)
  }, [filter])

  return (
    <>
      <Flex sx={{ justifyContent: 'flex-end', mb: 6 }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Icon icon="sort" color="text" sx={{ mr: 2 }} />
          <Label sx={{ width: 'auto', m: 0, mr: 3 }}>Sort by</Label>
          <Dropdown
            sx={{ minWidth: 262, mr: 3 }}
            name="sortBy"
            value={currentFilter.sortBy.field}
            options={[
              ...defaultSortBy,
              ...currentFilter.columns.filter(
                c => !Object.values(ESortFields).includes(c.value as ESortFields)
              ),
            ]}
            onChange={e => {
              const newSortBy = { ...currentFilter.sortBy, field: e.currentTarget.value }
              gotoPage(currentFilter.pagination, newSortBy)
            }}
          />
          <Dropdown
            name="sortBy"
            options={[
              { label: 'DESC', value: SortDirection.DESC },
              { label: 'ASC', value: SortDirection.ASC },
            ]}
            value={currentFilter.sortBy.direction}
            onChange={e => {
              const newSortBy = { ...currentFilter.sortBy, direction: e.currentTarget.value }
              gotoPage(currentFilter.pagination, newSortBy)
            }}
          />
          <Button
            onPress={() => {
              setFilterVisible(true)
            }}
            sx={{ color: 'primary', ml: 3, px: 18 }}
            icon="filter"
            variant="outline"
            label={copy.buttons.filter}
            color="black50"
            iconLeft
          />
        </Flex>
      </Flex>
      <Drawer visible={filterVisible}>
        <FilterTemplate
          key={key}
          onClose={() => {
            setFilterVisible(false)
            setCurrentFilter(filter)
          }}
          resetFilter={() => {
            setFilterVisible(false)
            setIsRange({
              foundedYear: false,
              lastFundingDate: false,
            })
            resetFilter()
            setKey(key + 1) // re-render UI when reset filter
            setErrorForm([])
          }}
          buttons={[
            {
              label: 'Apply',
              action: () => {
                onChangeFilter({ ...currentFilter })
                setFilterVisible(false)
              },
              disabled: !!errorForm.length,
              sx: { px: 16, py: 2, borderRadius: 8 },
            },
          ]}
        >
          {loading ? (
            <Updating loading />
          ) : (
            <>
              <Geography
                geography={geography?.getGeography}
                state={currentFilter.geography || ({} as GeographyType)}
                onChange={(geography: GeographyType) =>
                  setCurrentFilter({ ...currentFilter, geography })
                }
              />
              <TextField
                name="description"
                label="Description"
                placeholder="Eg: RegTech, Account-based"
                value={currentFilter.description}
                labelSx={{ pt: 4, mb: 3 }}
                onChange={({ target }) => {
                  setCurrentFilter({ ...currentFilter, description: target.value })
                }}
              />
              <FundingAmount
                title="Total Equity Funding"
                state={currentFilter.fundingAmount}
                onChange={(fundingAmount: FundingAmountType) =>
                  setCurrentFilter({ ...currentFilter, fundingAmount })
                }
              />
              <LaunchYear
                isRange={isRange.foundedYear}
                setIsRange={state => {
                  setIsRange({
                    ...isRange,
                    foundedYear: state,
                  })
                }}
                state={currentFilter.foundedYears}
                onChange={(years: any) =>
                  setCurrentFilter({ ...currentFilter, foundedYears: years })
                }
                title={'Founded year'}
              />
              <FundingDate
                name="fundingdate"
                isRange={isRange.lastFundingDate}
                setIsRange={state => {
                  setIsRange({
                    ...isRange,
                    lastFundingDate: state,
                  })
                }}
                state={currentFilter.lastFundingDates}
                onChange={dates => {
                  setCurrentFilter({
                    ...currentFilter,
                    lastFundingDates: dates,
                  })
                }}
                title={'Last funding date'}
                errorForm={errorForm}
                setErrorForm={setErrorForm}
              />
              <Dropdown
                name="source"
                label="Source"
                value={currentFilter.source}
                options={sourceOptions}
                onChange={({ target }) => {
                  setCurrentFilter({ ...currentFilter, source: target.value })
                }}
                labelSx={{ pt: 4, mb: 3 }}
              />
              <Dropdown
                name="status"
                label="Status"
                value={currentFilter.status}
                options={status}
                onChange={({ target }) => {
                  setCurrentFilter({ ...currentFilter, status: target.value })
                }}
                labelSx={{ pt: 4, mb: 3 }}
              />
              <Dropdown
                name="ftes"
                label="FTEs range"
                value={currentFilter.ftesRange}
                options={ftesRange}
                onChange={({ target }) => {
                  setCurrentFilter({ ...currentFilter, ftesRange: target.value })
                }}
                labelSx={{ pt: 4, mb: 3 }}
              />
            </>
          )}
        </FilterTemplate>
      </Drawer>
    </>
  )
}
export default CompanyFilter
