import { SxStyleProp } from 'theme-ui'

export const popoverZIndex = '11'
export const drawerZIndex = '990'
export const modalZIndex = '1000'
export const reasonPopverZIndex = '1010' // make sure this greater than modal zIndex (1000)
export const footerZIndex = '12'
export const CELL_SIZE = 'minmax(auto, 29px)'
export const CELL_BUFFER = '0px'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const HISTORY_DATE_FORMAT = 'MM/DD/YYYY'
export const TAXONOMY_HISTORY_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss'
export const TOOLTIP_SX = {
  fontSize: '0.9em',
  maxWidth: '300px',
  lineHeight: '1.5em',
  wordBreak: 'keep-all',
} as SxStyleProp

export const Function = {
  AGGREGATE: 'aggregate',
  AGGREGATE_INTERNAL: 'aggregate internal',
  MERGE: 'merge',
  CHANGE_DEFAULT: 'change default',
  REMOVE_SOURCE: 'remove source',
}

export const ATTACHMENT_TYPE = {
  OTHER: 'Other',
}

export const defaultLogo = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI0JEQkRCRCIgZD0iTTEyLDZWMS4ySDBWMjIuOEgyNFY2Wk00LjgsMjAuNEgyLjRWMThINC44Wm0wLTQuOEgyLjRWMTMuMkg0LjhabTAtNC44SDIuNFY4LjRINC44Wk00LjgsNkgyLjRWMy42SDQuOFpNOS42LDIwLjRINy4yVjE4SDkuNlptMC00LjhINy4yVjEzLjJIOS42Wm0wLTQuOEg3LjJWOC40SDkuNlpNOS42LDZINy4yVjMuNkg5LjZabTEyLDE0LjRIMTJWMThoMi40VjE1LjZIMTJWMTMuMmgyLjRWMTAuOEgxMlY4LjRoOS42Wm0tMi40LTkuNkgxNi44djIuNGgyLjRabTAsNC44SDE2LjhWMThoMi40WiIvPjwvc3ZnPg==`

export const DIMENSION_TYPE: any = {
  fintech: {
    1: 'Sector',
    2: 'Cluster',
  },
  insurtech: {
    1: 'Cluster',
    2: 'Value chain',
  },
  regtech: {
    1: 'Cluster',
    2: 'Risk',
  },
}
